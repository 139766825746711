.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 3vmax $p;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  > .logo > a > img {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }

  > .navItem {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    > a {
      color: $white_color;
      font-size: 1.2rem;
      font-weight: 400;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -5px;
        pointer-events: none;
        border-bottom: 3px solid;
        border-image: linear-gradient(
          90deg,
          $pale_robin_egg_blue_color 80%,
          $light_carmine_pink_color 20%
        );
        border-image-slice: 1;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
        width: 100%;
      }

      &:hover {
        color: $pale_robin_egg_blue_color;
        background: transparent;
        &::after {
          opacity: 1;
          transform: scaleX(1);
        }
      }
    }

    > .active {
      color: $pale_robin_egg_blue_color;
      &::after {
        opacity: 1;
      }
    }
  }

  > .contactBtn > a > button {
    padding: 0.5rem 2rem;
    border: 2px solid $pale_robin_egg_blue_color;
    color: $black_color;
    background-color: $pale_robin_egg_blue_color;
    font-weight: 450;
    font-size: 1.2rem;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;

    &:hover {
      background-color: #b2d8d7;
    }
  }

  .notification {
    background-color: $pale_robin_egg_blue_color;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15px;
    width: 15px;
    border-radius: 100%;
    border: 4px solid $white_color;
    color: $white_color;
    outline: none;
    position: relative;
    left: -12px;
  }

  .notification::after,
  .notification:before {
    content: "";
    position: absolute;
    background-color: $prussian_blue_color;
    z-index: 1;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    opacity: 0.7;
  }

  .notification:before {
    animation: notification 2s ease-out infinite;
  }

  .notification:after {
    animation: notification 2s ease-out infinite;
  }

  @keyframes notification {
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }

  > .navBtn {
    display: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    color: $white_color;
    font-size: 1.8rem;
    z-index: 150;
  }

  > .navBtnFixed {
    position: fixed;
    top: 1.3rem;
    right: 1.3rem;
  }
}
.head-bg {
  background-color: $outer_space_color;
  box-shadow: 0px 10px 4px 1px rgba(0, 0, 0, 0.25);
}

.navPhone {
  width: 100%;
  height: 100vh;
  background-color: $outer_space_color;
  padding: 8rem $p_base;
  position: fixed;
  top: 0;
  z-index: 1000;
  transform: translateY(-200%);
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  .navItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    > a {
      color: $white_color;
      font-size: 1.2rem;
      font-weight: 600;
      position: relative;

      &::after {
        content: "";
        height: 3px;
        width: 100%;
        border-bottom: 3px solid;
        border-image: linear-gradient(
          90deg,
          $light_carmine_pink_color 80%,
          $light_carmine_pink_color 20%
        );
        border-image-slice: 1;
        position: absolute;
        left: 0;
        bottom: -12px;
        opacity: 0;
        transition: all 0.2s;
        pointer-events: none;
      }

      &:hover {
        color: $pale_robin_egg_blue_color;
        &::after {
          opacity: 1;
        }
      }
    }

    > .active {
      color: $pale_robin_egg_blue_color;
      &::after {
        opacity: 1;
      }
    }
  }
  > .contactBtn > a > button {
    padding: 0.5rem 1rem;
    border: 2px solid $pale_robin_egg_blue_color;
    color: $black_color;
    background-color: $pale_robin_egg_blue_color;
    border-radius: 10px;
    font-weight: 500;
    font-size: 1.2rem;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      background-color: transparent;
      color: $white_color;
    }
  }
}

.navHeaderDisplay {
  transform: translateY(0);
}

@media screen and (max-width: 1400px) {
  .header {
    padding: 1.2vmax $p_md;

    > .navItem {
      gap: 1rem;
    }
  }
}
@media screen and (max-width: 1158px) {
  .header {
    padding: 1.5vmax $p_base;
    > .logo > a > img {
      filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
      width: 150px;
    }
    > .navItem {
      gap: 0.8rem;
    }

    > .contactBtn > a > button {
      padding: 0.4rem 0.8rem;

      font-size: 1rem;
    }
  }
}
@media screen and (max-width: 1025px) {
  .header {
    padding: 1.5vmax $p_sm;
    > .logo > a > img {
      filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
      width: 100px;
    }
    > .navItem {
      gap: 0.5rem;
    }

    > .contactBtn > a > button {
      padding: 0.3rem 1.5rem;
      font-size: 0.8rem;
    }
  }
}

@media screen and (max-width: 991.5px) {
  .header {
    > .logo > a > img {
      width: 150px;
    }
    > .navItem {
      display: none;
    }

    > .contactBtn > a > button {
      display: none;
    }

    > .navBtn {
      display: block;
    }
  }
}

.main-portfolio {
  background: linear-gradient(
    130deg,
    $outer_space_color 0%,
    rgba(62, 68, 73, 0.8) 100%
  );
}
.portfolio {
  width: 100%;
  padding: 4rem 150px;

  > .heading {
    display: flex;
    flex-direction: column;
    text-align: start;
    font-style: normal;
    line-height: 1.2;

    > h1 {
      position: relative;
      text-align: center;
      font-weight: 500;
      font-size: 36px;
      font-style: normal;
      color: $pale_robin_egg_blue_color;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3.5px;
        left: 0;
        bottom: -10px;
        background-color: $pale_robin_egg_blue_color;
      }
    }
    > p {
      display: flex;
      align-items: center;
      text-align: start;
      font-size: 18px;
      color: $white_color;
      line-height: 1.2;
      padding-bottom: 20px;
      font-weight: 400;
    }
  }
  > .portfolioNav {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 1.2rem 0 0 0;
    gap: 3rem;
    > a {
      color: $white_color;
      font-size: 1.5rem;
      font-weight: 500;
    }

    .activePortfolioNav {
      color: $pale_robin_egg_blue_color;
    }
  }

  > section {
    width: 100%;
    height: 100%;
    margin: 1rem 0;
    .portfolioItem {
      height: 33vmax;
      padding: 1rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      > .carousel-root {
        > .carousel {
          height: 100%;
          .slide {
            overflow: hidden;
          }
        }
      }
      > .header-text {
        text-align: left;
        width: 45%;

        > h3 {
          padding-bottom: 2rem;
          font-size: 2.4rem;
          color: $white_color;
        }
        > p {
          color: $white_color;
          letter-spacing: 1px;
          word-wrap: 1px;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
        }
      }
      > .carousel-root {
        width: 45%;
        position: relative;
        overflow: hidden;
        img {
          width: 89%;
          height: 80%;
          object-fit: cover;
          border-radius: 1rem;
          position: absolute;
          top: 0;
          left: 10%;
          z-index: 10;
          right: 1px;
          object-fit: fill;
          box-shadow: 0px 10px 4px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }

    .carousel-root {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .list-text {
      color: $white_color;
      position: relative;
      left: 15px;
      font-size: 24px;
      list-style-type: disc;
    }

    .carousel {
      .slider-wrapper {
        overflow: hidden;
        width: 100%;
        height: 100%;
      }

      .slider {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .slide {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .control-dots {
        position: absolute;
        width: 100%;
        text-align: center;

        .dot {
          position: relative;
          width: 8px;
          height: 8px;
          margin: 0 5px;
          background-color: $white_color;
          border-radius: 50%;
          display: inline-block;
          transition: background-color 0.3s ease;
          box-shadow: none;
        }
        .dot.selected {
          background-color: $pale_robin_egg_blue_color;
        }
      }
    }
  }
}
.crousal-image {
  height: calc(100% - 200px) !important;
  width: calc(100% - 90px) !important;
  object-fit: cover;
  border-radius: 1rem;
  top: 0;
  left: 10%;
  z-index: 10;
  right: 1px;
  box-shadow: 0px 10px 4px 0px rgba(0, 0, 0, 0.25);
}
.carousel.carousel-slider {
  position: initial !important;
}
.crousal-box-top {
  width: 45%;
  position: relative;
  overflow: hidden;
}

.crousal-border {
  width: 100%;
}
.crousal-border::before {
  content: "";
  border: 20px solid $pale_robin_egg_blue_color;
  position: absolute;
  height: calc(100% - 200px);
  border-radius: 25px;
  display: block;
  top: 50px;
  right: 65px;
  width: calc(100% - 108px);
  z-index: -1;
}
.portfolio-top-header {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.portfolio-fnt-lg {
  font-size: 1.2rem;
  line-height: 2;
  padding-left: 10px;
  color: $white_color;
}
.portfolio-headerfnt {
  font-size: 2rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
  color: $white_color;
}

@media screen and (max-width: 1600px) {
  .list-text {
    font-size: 18px !important;
  }
  .header-content {
    font-size: 17.5px !important;
  }
}
@media screen and (max-width: 1400px) {
  .portfolioItem {
    height: 33rem !important;
  }
  .portfolio {
    padding: 4rem 90px;
  }
  .crousal-border::before {
    height: calc(100% - 200px);
  }
  .crousal-image {
    width: calc(100% - 80px) !important;
  }
  .crousal-box-top {
    width: 50%;
  }
}
@media screen and (max-width: 1200px) {
  .portfolio {
    height: unset;
    padding: 6rem 90px;

    > .heading {
      > p {
        width: 80%;
      }
    }
    > .portfolioNav {
      padding: 2rem 0 0 0;

      > a {
        font-size: 1.3rem;
      }
    }

    > section {
      .portfolioItem {
        height: 45vmax;
        margin: auto;
        gap: 3rem;
        > aside {
          > p {
            color: $white_color;
            letter-spacing: 1px;
            word-wrap: 0px;
            line-height: 130%;
          }
        }
        > img {
          width: 60%;
          object-fit: cover;
          border-radius: 1rem;
        }
      }
    }
  }
  .list-text {
    font-size: 14px !important;
  }
  .crousal-image {
    position: relative;
    top: 20px;
    left: 2%;
  }
  .crousal-image {
    width: calc(100% - 65px) !important;
  }
  .crousal-border::before {
    height: calc(100% - 220px);
  }
}
@media only screen and (min-device-width: 1000px) and (max-device-width: 1050px) {
  .crousal-border::before {
    height: calc(100% - 265px);
  }
}
@media screen and (max-width: 991.5px) {
  .portfolio {
    height: unset;
    padding: 6rem 60px;
    > .heading {
      > p {
        width: 80%;
      }
    }
    > .portfolioNav {
      padding: 2rem 0 0 0;

      > a {
        font-size: 1.3rem;
      }
    }

    > section {
      .portfolioItem {
        margin: auto;
        gap: 2rem;
        > aside {
          width: 50%;
          > h3 {
            padding: 1rem 0;
          }
          > p {
            color: $white_color;
            letter-spacing: 0px;
            word-wrap: 0px;
            line-height: 130%;
            font-size: medium;
          }
        }
        > div {
          > img {
            width: 80%;
            left: 15%;
          }
        }
      }
    }
  }
  .portfolioItem {
    height: 54rem !important;
    flex-direction: column-reverse;
  }
  .header-text {
    width: 100% !important;
    height: 75%;
  }
  .crousal-box-top {
    width: 100%;
    height: 100%;
  }
  .crousal-image {
    width: 500px !important;
  }
  .crousal-border::before {
    height: 335px;
    top: 65px;
    right: 170px;
    width: 500px;
  }

  .list-text {
    font-size: 18px !important;
  }
  .header-text {
    > h3 {
      padding-bottom: 1rem !important;
    }
  }
}
@media screen and (max-width: 850.5px) {
  .crousal-border::before {
    display: none;
  }
}
@media screen and (max-width: 625px) {
  .portfolio {
    > .heading {
      > p {
        width: 100%;
      }
    }
    > .portfolioNav {
      padding: 2rem 0 2rem 0;
      gap: 1rem;
      > a {
        font-size: 1.2rem;
      }
    }

    > section {
      .portfolioItem {
        gap: 1rem;
      }
    }
  }
  .header-text {
    > h3 {
      font-size: 2rem !important;
    }
  }
}
@media screen and (max-width: 590px) {
  .portfolio {
    padding: 3rem 30px;

    > .portfolioNav {
      gap: 0.8rem;
      > a {
        font-size: 0.8rem;
      }
    }
    > section {
      .portfolioItem {
        gap: 0rem;

        > div {
          > img {
            width: 80%;
            left: 20%;
          }
        }
      }
    }
  }
  .crousal-image {
    width: 310px !important;
  }
  .crousal-box-top {
    width: 100%;
    height: 45%;
  }
}

@media screen and (max-width: 400px) {
  .portfolio {
    > .heading > h1 {
      text-align: center;
      font-size: 30px;
    }
    > .portfolioNav {
      gap: 0.8rem;
      > a {
        font-size: 0.93rem;
        color: $white_color;
      }
    }

    > section {
      .portfolioItem {
        > div {
          width: 100%;
        }
        > aside {
          display: none;
        }
      }
    }
  }
  .crousal-box-top {
    width: 100%;
    height: 30%;
  }
  .crousal-image {
    width: 245px !important;
  }
}
@media (min-width: 1921px) {
  .portfolio {
    max-width: 1920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .frame {
    bottom: 15.625rem;
    left: 0;
    z-index: -1;
    margin-left: 1%;
    height: 400px;
  }
}

.main-footer {
  padding: 3rem 150px;
  background: linear-gradient(
    130deg,
    $outer_space_color 0%,
    rgba(62, 68, 73, 0.8) 100%
  );
}
.footer {
  width: 100%;
  display: flex;
  > .left {
    color: $white_color;
    width: 50%;

    > img {
      filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    }
    > p {
      letter-spacing: 1px;
      line-height: 1.5;
      font-weight: 400;
      padding: 1rem 0 1.5rem 0;
      width: 100%;
      text-align: justify;
    }

    > .icon {
      > a {
        color: $white_color;
        > svg {
          font-size: 3.5rem;
          margin-right: 1rem;
          border: 1px solid $white_color;
          border-radius: 50%;
          padding: 10px;
        }
      }
    }
  }
  > .right {
    width: 50%;
    display: flex;
    justify-content: space-around;
    > .footercolumn {
      > h2 {
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-weight: 500;
        font-size: 2rem;
        color: $white_color;
      }
      > .item {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-left: 10px;
        > a {
          color: $white_color;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          letter-spacing: 1px;
          line-height: 1.3;
        }
      }
    }
  }
}
.copy-right {
  color: $white_color;
  font-size: 0.7rem;
}

@media screen and (max-width: 1300px) {
  .main-footer {
    padding: 3rem 90px;
  }
}
@media screen and (max-width: 991.5px) {
  .main-footer {
    padding: 3rem 60px;
  }
  .footer {
    gap: 2rem;
    > .left {
      width: 60%;
      > p {
        letter-spacing: 0px;
        font-size: 14px;
        word-spacing: 0px;
        line-height: 150%;
        padding: 1rem 0;
      }

      > .icon {
        > a {
          color: $white_color;
          > svg {
            font-size: 2rem;
            margin-right: 1rem;
          }
        }
      }
    }
    > .right {
      width: 40%;
      gap: 2rem;
      > .footercolumn {
        > .item {
          gap: 0.8rem;
          > a {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .main-footer {
    padding: 3rem 30px;
  }
  .footer {
    height: unset;
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .left {
      text-align: center;
      width: 100%;
      > p {
        letter-spacing: 1px;
        word-spacing: 1px;
      }

      > .icon {
        > a {
          color: $white_color;
          > svg {
            font-size: 2.5rem;
          }
        }
      }
    }
    > .right {
      width: 100%;
      justify-content: space-around;
    }
  }
}
@media (min-width: 1921px) {
  .footer {
    max-width: 1920px;
    width: 100%;
    margin: auto;
  }
}

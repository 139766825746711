.contact {
  width: 100%;
  padding: 4rem 150px;
  > .heading {
    display: flex;
    flex-direction: column;
    text-align: start;
    font-style: normal;
    line-height: 1.2;
    > h1 {
      display: inline;
      position: relative;
      font-weight: 500;
      &::after {
        content: "";
        position: absolute;
        width: 60%;
        height: 3px;
        left: 20%;
        bottom: -10px;
        background-color: $pale_robin_egg_blue_color;
      }
    }
    > p {
      text-align: left;
      color: $light_slate_gray_color;
      font-weight: 400;
      font-size: 16px;
      padding-bottom: 3rem;
      line-height: 1.2;
    }
  }

  > .contact_content {
    background: linear-gradient(
      130deg,
      $outer_space_color 0%,
      rgba(62, 68, 73, 0.8) 100%
    );
    border-radius: 20px;
    display: flex;
    gap: 1rem;
    padding: 3rem;

    > .left {
      width: 49%;
      background-color: $white_color;
      padding: 1rem 3rem;
      border-radius: 10px;

      > form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .group {
          display: flex;
          flex-direction: column;
          padding: 0.5rem 0;

          > label {
            color: $pale_robin_egg_blue_color;
            font-size: 1rem;
            padding-bottom: 0.4rem;
            font-weight: 500;
            font-size: 16px;
            line-height: normal;
          }
          > input {
            border: none;
            border-top: 2px solid $silver_sand_color;
            border-bottom: 2px solid $silver_sand_color;
            border-right: 2px solid $silver_sand_color;
            border-left: 2px solid $silver_sand_color;
            color: $silver_sand_color;
            font-size: 0.8rem;
            padding: 8px;
            outline: none;
            border-radius: 5px;
          }

          ::placeholder {
            color: $silver_sand_color;
            opacity: 1;
            font-size: 17px;
            font-style: normal;
            font-weight: 450;
          }
          #message {
            width: 100%;
            border-top: 2px solid $silver_sand_color;
            border-bottom: 2px solid $silver_sand_color;
            border-right: 2px solid $silver_sand_color;
            border-left: 2px solid $silver_sand_color;
            text-decoration: none;
            outline: none;
            display: block;
            font-size: 1rem;
            overflow-y: auto;
            resize: none;
            padding: 8px;
            border-radius: 4px;
          }
          #message::-webkit-scrollbar {
            display: block !important;
            width: 8px;
            cursor: pointer !important;
          }
          #message::-webkit-scrollbar-thumb {
            background-color: $pale_robin_egg_blue_color;
            border-radius: 1rem;
            cursor: pointer !important;
          }

          input[placeholder],
          [placeholder],
          *[placeholder] {
            color: $black_color !important;
          }
          > .text-danger {
            color: red;
          }
        }

        > button {
          padding: 12px;
          border: 2px solid $pale_robin_egg_blue_color;
          color: $outer_space_color;
          background-color: $pale_robin_egg_blue_color;
          border-radius: 10px;
          font-weight: 420;
          font-size: 18px;
          line-height: normal;
          font-size: 1rem;
          transition: all 0.3s;
          margin: 1rem 0;
          cursor: pointer;
          filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.25));

          &:hover {
            background-color: transparent;
            color: $black_color;
          }
        }
      }
    }
    > .mid {
      width: 2px;
      background-color: #d9d9d9;
      margin-left: 20px;
    }
    > .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 449px;
      margin: auto;
      gap: 2rem;

      > .icon {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        color: $white_color;

        > div {
          display: flex;
          align-items: flex-start;
          gap: 2rem;

          > svg {
            background-color: $pale_robin_egg_blue_color;
            padding: 8px;
            font-size: 2rem;
            border-radius: 50%;
            min-width: 30px;
            width: 30px;
            height: 30px;
          }

          > p {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
  .group input::placeholder,
  .group textarea::placeholder {
    font-size: 0.8rem !important;
  }
  .contact-follow {
    color: $white_color;
    margin-bottom: 10px;
  }

  .clr-form {
    color: $light_carmine_pink_color;
    padding-left: 5px;
  }

  .right-top-wd {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .iconlogo a svg {
    font-size: 2rem;
    margin-right: 1rem;
    border: 1px solid $white_color;
    border-radius: 50%;
    padding: 5px;
    color: $white_color;
  }
  .contact-top-header {
    display: flex;
    align-items: center;
  }
  .contact-fnt-lg {
    font-size: 1.2rem;
    line-height: 2;
    padding-left: 10px;
    text-transform: uppercase;
  }
  .contact-headerfnt {
    font-size: 2rem;
    font-weight: 500;
    padding-bottom: 0.5rem;
  }
}

@media screen and (max-width: 1100px) {
  .contact {
    width: 100%;
    padding: 6rem 90px;
    min-height: unset;

    > .heading {
      > p {
        width: 60%;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .contact {
    padding: 6rem 60px;

    > .heading {
      > p {
        width: 100%;
      }
    }

    > .contact_content {
      gap: 1rem;
      padding: 2rem;

      > .left {
        padding: 1rem;
      }
    }
  }
}
@media screen and (max-width: 768.5px) {
  .contact {
    padding: 3rem 60px;
  }
  .left {
    width: 100% !important;
  }
  .right {
    width: 100% !important;
  }

  .contact_content {
    flex-direction: column;
  }
}
@media screen and (max-width: 550px) {
  .contact {
    padding: 3rem 30px;

    > .contact_content {
      height: unset;
      gap: 1rem;
      padding: 1rem;
      flex-direction: column;

      > .left {
        width: 100%;
        padding: 1rem;
      }
      > .right {
        width: 100%;
      }
      > .mid {
        display: none;
      }
    }
  }
  .contact-headerfnt {
    font-size: 28px;
    text-align: left;
  }
}
@media (min-width: 1921px) {
  .contact {
    max-width: 1920px;
    width: 100%;
    margin: auto;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@800&display=swap");
$Jost: "Jost", sans-serif;
$Inter: "Inter", sans-serif;

$outer_space_color: #464d51;
$pale_robin_egg_blue_color: #8cdcd9;
$light_carmine_pink_color: #f16a6a;
$black_color: black;
$white_color: white;
$silver_sand_color: #c2c2c2;
$weldon_blue_color: #8296a2;
$light_slate_gray_color: #708795;
$azureish_white_color: #d6ebea;
$prussian_blue_color: #003049;

$p: 10vmax;
$p_md: 5vmax;
$p_base: 4vmax;
$p_sm: 2vmax;
$p_zero: 0;

* {
  padding: 0;
  margin: 0;
  font-family: $Inter;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: $Jost;
  scroll-padding-top: 5rem;
}
a {
  text-decoration: none;
}

::-webkit-scrollbar {
  display: none;
}

@import "./components/Layout/Header/Header";
@import "./components/Home/Home";
@import "./components/Reasons/Reasons";
@import "./components/Services/Services";
@import "./components/Work/Work";
@import "./components/Layout/Footer/Footer";
@import "./components/Portfolio/Portfolio";
@import "./components/Contact/Contact.scss";
@import "./components/Testimonial/Testimonial";
@import "./components/Certified/certified.scss";

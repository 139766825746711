.home {
  position: relative;
  height: 100vh; /* Set the height of the header */
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    130deg,
    $outer_space_color 0%,
    rgba(62, 68, 73, 0.8) 100%
  );
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > .pd-right {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    left: 0px;
    top: 0;
    padding: 0 10vmax;
    width: 100%;
    height: 100%;
    .main-text {
      &.main-head {
        margin-bottom: 25px;
        margin-top: 5px;
      }
      &:first-of-type {
        font-size: 40px;
        color: $white_color;
      }
    }
    .industries-text {
      padding-bottom: 32px;
      display: inline-block;
      width: 100%;
    }
    > p {
      color: $white_color;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
  }
  #tsparticles {
    width: 100vw;
    height: 100vh;
    position: relative;
  }

  .home-content {
    width: 100%;
    margin-top: 5px;
    > p {
      color: $white_color;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      padding-top: 32px;
      line-height: 24px;
      letter-spacing: 0.4px;
      max-width: 600px;
    }
  }
}
.pd-left {
  position: absolute;
  right: 0px;
  padding: 0 15vmax;
  width: 50%;
  height: auto;
}

@media (max-width: 1200px) {
  .pd-left {
    display: none;
  }
}
@media (max-width: 991.5px) {
  .home {
    height: 75vh;
    min-height: 75vh;
  }
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
  .home {
    > div {
      width: 90%;
      margin-inline: auto;
    }
  }
  .pd-right {
    padding: 0 30px !important;
    p {
      font-size: 10px !important;
    }
  }
  .main-text {
    font-size: 24px !important;
  }
  .industries-text {
    padding-bottom: 16px !important;
  }
}

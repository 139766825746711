.services {
  width: 100%;
  padding: 4rem 150px;
  > .heading {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: start;
    line-height: 1.2;

    > h1 {
      position: relative;
      text-align: left;
      font-weight: 500;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        left: 0;
        bottom: -10px;
        background-color: $pale_robin_egg_blue_color;
      }
    }
    > p {
      display: flex;
      align-items: center;
      justify-content: start;
      text-align: start;
      font-size: 16px;
      color: $outer_space_color;
      padding-bottom: 40px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .container {
    height: 100%;
    width: 100%;
    display: flex;
    margin-top: 1rem;
    > .left {
      width: 20rem;
      display: flex;
      position: relative;

      > div {
        padding: 2.2rem 3rem;
        &:first-of-type {
        }
        &:last-of-type {
          position: sticky;
          width: 100%;
          left: 12%;
          top: 0;
          bottom: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 1.8rem;
          height: 100%;
          > div {
            > .techno {
              width: 24rem;
              min-height: 144px;
              padding: 1.5rem;
              background: $white_color;
              color: $black_color;
              cursor: pointer;
              filter: drop-shadow(0px 4px 55px rgba(0, 0, 0, 0.15));
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              > p {
                color: $white_color;
                font-weight: 600;
              }
              &:hover {
              }
            }

            > .selected {
              font-weight: 600;
              background-image: linear-gradient(
                67deg,
                $pale_robin_egg_blue_color 0%,
                $azureish_white_color 95%
              );
            }
          }
        }
      }
    }
    > .right {
      width: 80%;
      background: linear-gradient(
        130deg,
        $outer_space_color 0%,
        rgba(62, 68, 73, 0.8) 100%
      );

      position: sticky;
      top: 12vw;
      z-index: -1;
      left: 0;
      height: 100%;
      overflow: hidden;

      > article {
        padding: 5rem 10rem;
        &:first-of-type {
          > h1 {
            text-align: start;
            padding-bottom: 1.5rem;
            font-size: 2rem;
            color: $white_color;
          }
          > p {
            color: $white_color;
            letter-spacing: 1px;
            line-height: 1.5;
            text-align: justify;
            font-size: 1.1rem;
            font-weight: 300;
            margin-bottom: 2.2rem;
          }
        }
        &:last-of-type {
          display: flex;
          flex-direction: column;

          > h3 {
            padding-bottom: 2rem;
            color: $white_color;
            font-size: 1.3rem;
          }
          > div {
            display: flex;
            align-items: center;
            color: $white_color;
            gap: 1.2rem;
            > img {
              width: 90px;
              height: 90px;
            }
          }
        }

        .tech-stack {
          padding: 10px;
        }
      }
    }
  }
}
.mob-description {
  display: none;
}
.fnt-clr {
  color: $black_color !important;
  font-size: 1.5rem;
}
.left-flx {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.left-num {
  font-weight: 600;
  font-size: 6rem;
  line-height: 1;
  opacity: 8%;
  text-align: right;
}

@media screen and (max-width: 1200px) {
  .services {
    padding: 6rem 90px;
    > .heading {
      > p {
        width: 75%;
      }
    }

    .container {
      > .left {
        width: 40%;
        display: flex;
        position: relative;

        > div {
          padding: 2.2rem 3rem;
          &:first-of-type {
            width: 65%;
            background-image: linear-gradient(
              102deg,
              $light_slate_gray_color 0%,
              rgba(112, 135, 149, 0) 100%
            );
          }
          &:last-of-type {
            left: 6%;
            > div {
              > .techno {
                width: 250%;
              }
            }
          }
        }
      }
      > .right {
        width: 60%;
        background-color: $pale_robin_egg_blue_color;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > article {
          padding: 2.2rem 3rem;
          &:first-of-type {
            > h1 {
              margin-bottom: 2.5rem;
            }
            > p {
              line-height: 200%;
            }
          }
        }
      }
    }
  }
  .left-num {
    font-size: 5rem;
  }
  .fnt-clr {
    font-size: 1rem;
  }
}
@media screen and (max-width: 991.5px) {
  .services {
    padding: 6rem 60px;
    > .heading {
      > p {
        width: 75%;
      }
    }
    .container {
      > .left {
        width: 100%;

        > div {
          &:first-of-type {
            width: 100%;
          }
          &:last-of-type {
            > div {
              > .techno {
                width: 100%;
              }
            }
          }
        }
      }
      > .right {
        display: none;

        > article {
          padding: 1rem 3rem;
          &:first-of-type {
            > h1 {
              margin-bottom: 2rem;
            }
            > p {
              line-height: 120%;
            }
          }
        }
      }
    }
  }
  .mob-techno.mob-selected .mob-description {
    display: block;
    padding: 40px 20px 0;
  }
  .mob-title-service {
    font-size: 23px;
  }
  .mob-description-service {
    padding-top: 10px;
  }
}
.service-top-header {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.service-fnt-lg {
  font-size: 1.2rem;
  line-height: 2;
  padding-left: 10px;
}
.service-headerfnt {
  font-size: 2rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
}

@media screen and (max-width: 770px) {
  .services {
    padding: 3rem 60px;
    margin-bottom: unset;

    > .heading {
      > p {
        width: 100%;
      }
    }
    .container {
      position: static;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      > .left {
        width: 100%;
        height: auto;

        > div {
          padding: 2.2rem 3rem;

          &:last-of-type {
            > div {
              > .techno {
                width: 100%;
              }
            }
          }
        }
      }
      > .right {
        width: 100%;
        height: unset;
      }
    }
  }
  .service-headerfnt {
    font-size: 28px;
  }
}
@media screen and (max-width: 550.5px) {
  .services {
    padding: 3rem 30px;
  }
  .left-num {
    font-size: 4rem;
  }
  .fnt-clr {
    font-size: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .services {
    .container {
      > .left {
        > div {
          &:last-of-type {
            left: 0;
            > div {
              > .techno {
              }
            }
          }
        }
      }
    }
  }
  .left-flx {
    flex-direction: column;
  }
  .services .container > .left > div {
    padding: 1rem;
  }
}
@media (min-width: 1921px) {
  .services {
    max-width: 1920px;
    width: 100%;
    margin: auto;
  }
}

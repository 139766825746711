.max-wd {
  padding: 3rem 150px;
}
.reasons {
  position: relative;
  width: 100%;
  margin: auto;
  > .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 75%;
  }
  > article {
    &:first-of-type {
      > p {
        color: #4c5057;
        font-size: 20px;
        padding-left: 50px;
      }
    }
    &:last-of-type {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      gap: 180px;
      padding: 3rem 0;
      > div {
        > h1 {
          font-size: 4rem;
          color: $light_carmine_pink_color;
        }
        > p {
          font-size: 2rem;
          color: $white_color;
          text-align: center;
        }
      }
    }
  }
}
.fnt-lg {
  font-size: 36px;
  font-weight: 500;
  padding-left: 10px;
}
.pointers {
  display: flex;
  justify-content: space-evenly;
  padding-top: 3rem;
  font-size: 1.5rem;
}
.top-header {
  display: flex;
  align-items: center;
}
.list-space {
  padding-bottom: 1rem;
}
.fnt-xs {
  font-size: 1.5rem;
}

.subtitles {
  display: flex;
  flex-direction: row;
  color: $black_color;
  gap: 2rem;
  font-size: 1.1rem;
  position: relative;
  padding: 2rem $p;
  align-items: center;
  justify-content: center;

  .left-subtitle {
    display: flex;
    gap: 1rem;
    float: left;
    position: absolute;
    left: 0px;
    padding: 1rem;
    font-size: 12px;
  }

  .right-subtitle {
    display: flex;
    gap: 1rem;
    float: right;
    position: absolute;
    right: 0px;
    font-size: 12px;
  }
}
.border-wd {
  margin: 0 150px;
  border-bottom: 1px solid $light_slate_gray_color;
}

@media screen and (max-width: 1300px) {
  .max-wd {
    padding: 3rem 90px;
  }
  .border-wd {
    margin: 0 90px;
  }
}

@media screen and (max-width: 991.5px) {
  .max-wd {
    padding: 3rem 60px;
  }
  .border-wd {
    margin: 0 60px;
  }
  .reasons {
    height: unset;

    > article {
      gap: 1rem;

      &:last-of-type {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        align-items: center;
        > div {
          > h1 {
            font-size: 2rem;
            color: $light_carmine_pink_color;
          }
          > p {
            font-size: 2rem;
            color: $white_color;
          }
        }
      }
    }
    .fnt-xs {
      font-size: 1.2rem;
    }
    .pointers {
      font-size: 1rem;
    }
  }
}
@media screen and (max-width: 768.5px) {
  .fnt-xs {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 600px) {
  .pointers {
    display: block;
    padding-left: 50px;
  }
  .mob-spc {
    padding-bottom: 1rem;
  }
}
@media screen and (max-width: 450.5px) {
  .max-wd {
    padding: 3rem 30px;
  }
  .fnt-lg {
    font-size: 28px;
  }
  .mob-flex {
    flex: 0 0 40%;
  }
  .list-space,
  .mob-spc,
  .mob-fnt {
    font-size: 14px;
  }
  .reasons {
    > article {
      &:first-of-type {
        > p {
          font-size: 14px;
        }
      }
      &:last-of-type {
        flex-wrap: wrap;
      }
    }
  }
}
@media (min-width: 1921px) {
  .max-wd {
    max-width: 1920px;
    width: 100%;
    margin: auto;
  }
}

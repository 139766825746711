.work {
  padding: 4rem 150px;
  width: 100%;
  padding-bottom: 8rem;

  .heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 1.2;
    align-items: flex-start;
    h1 {
      position: relative;
      text-align: center;
      font-weight: 500;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      line-height: 1;
      color: $outer_space_color;
      font-weight: 400;
      font-size: 16px;
    }
  }
  .work-top-header {
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
  .work-fnt-lg {
    font-size: 1.2rem;
    line-height: 2;
    padding-left: 15px;
  }
  .work-headerfnt {
    font-size: 2rem;
    font-weight: 500;
    padding-bottom: 0.5rem;
  }

  .phoneViewWork {
    display: none;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
    > .workItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      > .icon {
        width: 20%;
        height: 190px;
        width: 200px;
        border-radius: 50%;
        border: 25px solid $outer_space_color;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        background-color: $white_color;
        > img {
          width: 80%;
          height: 80%;
        }
      }

      .cynIcon {
        border-color: $pale_robin_egg_blue_color;
      }

      > .text {
        text-align: center;
        width: 50%;
        > h2 {
          font-weight: 500;
        }
        > p {
          text-align: center;
          color: $outer_space_color;
        }
      }
    }
  }
}
.clr-rd {
  color: $light_carmine_pink_color;
  font-family: Inter;
}
.clr-blu {
  color: $pale_robin_egg_blue_color;
}
.container-work {
  display: flex;
  width: 100%;
  padding-top: 4rem;
}
.container-w-25 {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6rem;
}
.cont-text {
  height: 50%;
  min-height: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .detail-text {
    text-align: center;
    color: $light_slate_gray_color;
    line-height: 1.5;
    letter-spacing: 1px;
  }
}
.cont-icon {
  height: 190px;
  width: 200px;
  border-radius: 50%;
  border: 25px solid $pale_robin_egg_blue_color;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: $white_color;
  img {
    width: 80%;
    height: 80%;
  }
}
.cont-left-line {
  border-color: $light_carmine_pink_color;
}
.cont-left-line::before {
  content: "";
  position: absolute;
  z-index: -10;
  height: 2px;
  width: 375px;
  background-color: $weldon_blue_color;
  transform: rotate(43deg) translateX(40%);
}
.cont-right-line::before {
  content: "";
  position: absolute;
  z-index: -1;
  height: 2px;
  width: 350px;
  background-color: $weldon_blue_color;
  transform: rotate(-43deg) translateX(40%);
}

@media screen and (max-width: 1300px) {
  .work {
    margin-bottom: 5rem;
    padding: 4rem 90px;
  }
}
@media screen and (max-width: 1100px) {
  .cont-left-line::before {
    transform: rotate(50deg) translateX(40%);
  }
  .cont-right-line::before {
    transform: rotate(-50deg) translateX(40%);
  }
}

@media screen and (max-width: 991.5px) {
  .work {
    height: auto;
    width: 100%;
    margin-bottom: 0;
    padding: 6rem 60px;
    > .heading {
      > p {
        width: 90%;
      }
    }
    .container-work {
      flex-direction: column;
    }
    .container-w-25 {
      width: 100%;
      flex-direction: row;
    }
    .reverse {
      flex-direction: row-reverse !important;
    }
    .cont-icon {
      height: 140px;
      width: 140px;
      min-width: 140px;
    }
    .cont-left-line::before {
      content: "";
      position: absolute;
      z-index: -10;
      height: 2px;
      left: 30px;
      width: 200px;
      background-color: $weldon_blue_color;
      transform: rotate(90deg) translateX(40%);
    }
    .cont-right-line::before {
      content: "";
      position: absolute;
      z-index: -1;
      height: 2px;
      width: 200px;
      left: 30px;
      background-color: $weldon_blue_color;
      transform: rotate(90deg) translateX(40%);
    }
    .work-headerfnt {
      font-size: 28px;
      text-align: left;
      font-weight: 500;
      padding-bottom: 0.5rem;
    }
  }
}
@media screen and (max-width: 850px) {
  .cont-left-line::before {
    left: 4%;
  }
  .cont-right-line::before {
    left: 4%;
  }
}
@media screen and (max-width: 700px) {
  .work {
    height: unset;
    > .heading {
      > p {
        width: 90%;
      }
    }
    > .container {
      display: none;
    }

    .phoneViewWork {
      display: none;
    }
    .cont-icon {
      height: 120px;
      width: 125px;
      min-width: 120px;
    }
    .container-w-25 {
      width: 100%;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 20px;
    }
    .reverse {
      flex-direction: column-reverse !important;
    }
    .cont-left-line::before {
      display: none;
    }
    .cont-right-line::before {
      display: none;
    }
  }
}
@media screen and (max-width: 450px) {
  .work {
    overflow: hidden;
    padding: 3rem 30px;

    > .heading {
      > p {
        width: 100%;
      }
    }

    .phoneViewWork {
      > .workItem {
        > .icon {
          width: 20%;
          height: 130px;
          width: 150px;
          border: 18px solid $outer_space_color;

          padding: 0.5rem;

          > img {
            width: 70%;
            height: 70%;
          }
        }

        > .text {
          text-align: center;
          width: 100%;
          > h2 {
            font-size: 1.4rem;
          }
          > p {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1921px) {
  .work {
    max-width: 1920px;
    width: 100%;
    margin: auto;
  }
  .container-work {
    max-width: 1200px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1920px) and (min-width: 1250px) {
  .container-work {
    max-width: 1200px;
    margin: 0 auto;
  }
}

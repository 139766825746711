.testimonial {
  width: 100%;
  padding: 4rem 150px;
  position: relative;
  .heading {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: start;
    padding-bottom: 4rem;
    line-height: 1.2;
  }
}
.testimonial-top-header {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.testimonial-fnt-lg {
  font-size: 1.2rem;
  line-height: 2;
  padding-left: 10px;
}
.testimonial-headerfnt {
  font-size: 2rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
}
.text-clr {
  color: $black_color;
  font-size: 1.5rem;
}
.swiper {
  min-height: 300px;
}

.next-ctrl-1 {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  text-align: center;
  margin: 5px 0;
  right: 3%;
  top: 55%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev-ctrl-1 {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  text-align: center;
  margin: 5px 0;
  left: 3%;
  top: 55%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonial-swiper {
  padding-top: 15px !important;
}
.testimonial-item {
  position: relative;
  margin: 0 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.testimonial-quoteIcon {
  position: absolute;
  top: -25px;
  left: 11%;
  width: 10%;
  z-index: 2;
}
.testimonial-quoteIcon-bottom {
  position: absolute;
  bottom: -40px;
  right: 10%;
  width: 10%;
  z-index: 2;
}
.testimonial-quoteIcon img,
.testimonial-quoteIcon-bottom img {
  width: 100%;
  height: auto;
}

.testimonial-cont-top {
  position: relative;
  padding: 50px 40px 40px 40px;
  border: 10px solid transparent;
  border-image: linear-gradient(
    130deg,
    $outer_space_color 0%,
    rgba(62, 68, 73, 0.4) 100%
  );
  border-image-slice: 1;
  border-top: 0;
  border-bottom: 0;
  min-height: 235px;
}
.testimonial-cont-bottom {
  position: relative;
}

.testimonial-cont-top::after {
  content: "";
  position: absolute;
  height: 10px;
  background: linear-gradient(
    130deg,
    $outer_space_color 0%,
    rgba(62, 68, 73, 0.4) 100%
  );
  top: 0;
  width: 8%;
  left: 0;
}

.testimonial-cont-top::before {
  content: "";
  position: absolute;
  height: 10px;
  background: linear-gradient(
    130deg,
    $outer_space_color 0%,
    rgba(62, 68, 73, 0.4) 100%
  );
  top: 0;
  width: 78%;
  right: 0;
}
.testimonial-cont-bottom::after {
  content: "";
  position: absolute;
  height: 10px;
  background: linear-gradient(
    130deg,
    $outer_space_color 0%,
    rgba(62, 68, 73, 0.4) 100%
  );
  top: 0;
  width: 78%;
  left: 0;
}

.testimonial-cont-bottom::before {
  content: "";
  position: absolute;
  height: 10px;
  background: linear-gradient(
    130deg,
    $outer_space_color 0%,
    rgba(62, 68, 73, 0.4) 100%
  );
  top: 0;
  width: 8%;
  right: 0;
}
.swiper-pagination-bullet-active {
  background: $light_carmine_pink_color !important ;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -6px !important;
}
@media screen and (max-width: 1300px) {
  .testimonial {
    padding: 6rem 90px;

    > section {
      padding: 2.5rem 1rem;
      > article {
        height: 285px;
        .test_item {
          > .avatar_details {
            padding: 1.25rem 1rem;
            > p {
              color: $white_color;
              letter-spacing: 0px;
              line-height: 120%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991.5px) {
  .testimonial {
    padding: 6rem 60px;

    > section {
      padding: 2.5rem 1rem;

      > article {
        height: 240px;
        .test_item {
          flex-direction: column;
          .avatar {
            height: 150px;
            width: 150px;
          }
          > .avatar_details {
            padding: 0;
            margin-left: 0px;
            > p {
              color: $white_color;
              letter-spacing: 0px;
              line-height: 120%;
            }
          }
        }
      }
    }
  }
  .testimonial-quoteIcon {
    top: -40px;
  }
}

@media screen and (max-width: 550.5px) {
  .testimonial-cont-top {
    min-height: 150px;
  }
  .testimonial {
    padding: 3rem 30px;

    > section {
      margin-top: 3.5rem;
      > article {
        width: 100%;
      }
    }
  }
  .testimonial-headerfnt {
    font-size: 28px;
  }
}
@media screen and (max-width: 430.5px) {
  .testimonial-quoteIcon {
    top: -10px;
  }
  .testimonial-quoteIcon-bottom {
    bottom: -25px;
  }
  .swiper {
    min-height: 250px;
  }

  .next-ctrl-1 {
    top: 62%;
  }

  .prev-ctrl-1 {
    top: 62%;
  }
}

.certified {
  width: 100%;
  padding: 4rem 150px;
  background: linear-gradient(
    130deg,
    $outer_space_color 0%,
    rgba(62, 68, 73, 0.8) 100%
  );
}
.certified-top-header {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.certified-fnt-lg {
  font-size: 1.2rem;
  line-height: 2;
  padding-left: 10px;
  color: $white_color;
}
.certified .heading {
  display: flex;
  flex-direction: column;
  text-align: start;
  font-style: normal;
  line-height: 1.2;
  padding-bottom: 40px;
}
.certified-headerfnt {
  font-size: 2rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
  color: $white_color;
}
.certified .subtitle {
  display: flex;
  align-items: center;
  text-align: start;
  font-size: 16px;
  color: $white_color;
  line-height: 1.2;
  padding-bottom: 20px;
  font-weight: 400;
}
.clr-primary {
  color: $outer_space_color;
}
.certified-image {
  border-radius: 8px;
  width: fit-content;
}
.certified-border {
  width: fit-content;
  min-height: 144px;
  padding: 1.5rem;
  background: #f4fafc;
  cursor: pointer;
  filter: drop-shadow(0px 4px 55px rgba(0, 0, 0, 0.5));
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.certified-content {
  width: 75%;
  font-size: 1.5rem;
}
@media screen and (max-width: 1300px) {
  .certified {
    padding: 4rem 90px;
  }
}
@media screen and (max-width: 991.5px) {
  .certified {
    padding: 4rem 60px;
  }
  .certified-border {
    flex-direction: column;
    gap: 3rem;
  }
  .certified-content {
    width: 100%;
    text-align: justify;
    font-size: 1.2rem;
  }
  .certified-headerfnt {
    font-size: 28px;
  }
}
@media screen and (max-width: 550.5px) {
  .certified {
    padding: 4rem 30px;
  }
}
